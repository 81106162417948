.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes animateCircle {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.3);
  }
}
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.circle {
  transition: r 3s ease-in-out;
  transform-origin: center;
}

.circle.visible {
  r: 40;
}

.circle.pop {
  animation: pop-in 3s ease-in-out;
}

@keyframes pop-in {
  0% {
    r: 0;
    opacity: 0;
  }
  100% {
    r: 40;
    opacity: 1;
  }
}
